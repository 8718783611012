import ApiService from "./api.service";

const P1Service = {
  async getP1Data() {
    return await ApiService.commonGETRequest("/p1/");
  },
  async uploadP1Data(data) {
    return await ApiService.commonPOSTRequest("/p1/", {}, data, {
      "Content-Type": "multipart/form-data",
    });
  },
  async getMedicalPlaces() {
    return await ApiService.commonGETRequest("/p1/place/");
  },
  async createMedicalPlace(data) {
    return await ApiService.commonPOSTRequest("/p1/place/", {}, data);
  },
  async updateMedicalPlace(data) {
    return await ApiService.commonPATCHRequest(
      `/p1/place/${data.id}/`,
      {},
      data
    );
  },
  async deleteMedicalPlace(data) {
    return await ApiService.commonDELETERequest(`/p1/place/${data.id}/`);
  },
};

export default P1Service;
