<template>
  <v-layout column :style="{ padding: '0px 10px 0px 10px !important' }">
    <v-layout column>
      <P1MedicalWorkerForm
        ref="workerform"
        :p1Data.sync="p1Data"
      ></P1MedicalWorkerForm>
      <v-divider class="ma-4"></v-divider>
      <P1MedicalOwnerForm
        ref="ownerform"
        :p1Data.sync="p1Data"
      ></P1MedicalOwnerForm>
      <P1MedicalPlacesForm
        ref="mpf"
        v-if="visibleMedicalPlaces"
        :medicalPlaces="medicalPlaces"
        :p1Data="p1Data"
      ></P1MedicalPlacesForm>
      <v-layout>
        <v-spacer></v-spacer>
        <v-flex shrink>
          <DefaultButton @click.prevent="save" class="mx-4 px-0 mb-6" primary
            >Zapisz</DefaultButton
          >
        </v-flex>
      </v-layout>
    </v-layout>
    <ConfirmDialog
      v-if="medicalPlaceWarningPopupVisible"
      :value.sync="medicalPlaceWarningPopupVisible"
      title="Uwaga!"
      text="Podmiot leczniczy powinien mieć ustawione przynajmniej jedno miejsce udzielania świadczeń medycznych."
      negativeButtonText="Zamknij"
      positiveButtonText=""
    >
    </ConfirmDialog>
  </v-layout>
</template>

<script>
import P1Service from "@/services/p1.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import DoctorReloadMixin from "@/mixins/DoctorReloadMixin";
import ResourcesService from "@/services/resources.service";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [ProxyCRUDMixin, DoctorReloadMixin],
  data() {
    return {
      p1Data: {},
      medicalPlaceWarningPopupVisible: false,
    };
  },
  computed: {
    visibleMedicalPlaces() {
      return (
        this.p1Data &&
        this.p1Data.organization_cell === "O" &&
        this.p1Data.is_owner
      );
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    P1MedicalWorkerForm: () =>
      import("@/components/forms/p1/P1MedicalWorkerForm"),
    P1MedicalOwnerForm: () =>
      import("@/components/forms/p1/P1MedicalOwnerForm"),
    P1MedicalPlacesForm: () =>
      import("@/components/forms/p1/P1MedicalPlacesForm"),
  },
  methods: {
    ...mapActions("ui", ["updateSyncP1", "updateAccessToMedicalEmployees"]),
    reloadData() {
      this.fetch();
    },
    fetch() {
      this.beforeRequest();
      P1Service.getP1Data()
        .then((reply) => {
          this.p1Data = reply;
          this.updateSyncP1(this.p1Data.active);
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    },
    save() {
      if (
        this.p1Data.is_owner &&
        this.p1Data.organization_cell == "O" &&
        !this.$refs.mpf.hasMedicalPlace
      ) {
        this.medicalPlaceWarningPopupVisible = true;
      }

      if (this.p1Data.active && !this.$refs.workerform.validate()) {
        return;
      }

      if (this.p1Data.is_owner && !this.$refs.ownerform.validate()) {
        return;
      }

      this.beforeRequest();
      let formData = new FormData();
      const activeKeys = [
        "first_name",
        "last_name",
        "profession_number",
        "profession_role",
        "profession_group",
      ];
      for (let key in this.p1Data) {
        if (!this.p1Data.active && activeKeys.includes(key)) {
          continue;
        }

        if (
          (key != "tls_cert" && key != "wss_cert" && key != "book") ||
          this.p1Data[key] == "" ||
          typeof this.p1Data[key] != "string"
        ) {
          formData.append(
            key,
            this.p1Data[key] === undefined || this.p1Data[key] === null
              ? ""
              : this.p1Data[key]
          );
        }
      }
      P1Service.uploadP1Data(formData)
        .then((reply) => {
          this.p1Data = reply;
          this.updateSyncP1(this.p1Data.active);
          this.handleSuccess("Zmiany zostały zapisane.");
          this.checkMedicalEmployeesAccess();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError(error);
        });
    },
    checkMedicalEmployeesAccess() {
      return ResourcesService.checkMedicalEmployeesAccess()
        .then(() => this.updateAccessToMedicalEmployees(true))
        .catch(() => this.updateAccessToMedicalEmployees(false));
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>